import { Params } from '../../../app-shared-elements/_interfaces/params.interface';

export class InitUserDevices {
    static readonly type = '[CompanyProfileState] InitUserDevices';
}

export class UpdateCompanyUserDevices {
    static readonly type = '[CompanyProfileState] UpdateCompanyUserDevices';
}

export class GetCompanyProfileDevices {
    static readonly type = '[CompanyProfileState] GetCompanyProfileDevices';

    constructor(public userId: string) {}
}

export class ChangeCompanyProfileParams {
    static readonly type = '[CompanyProfile] ChangeCompanyProfileParams';
    constructor(public params: Params) {}
}

export class GetCompanyRoleInfo {
    static readonly type = '[CompanyProfile] GetCompanyRoleInfo';
    constructor(public roleId: string) {}
}
